import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`BySmarkio: Added endpoint to submit survey’s answers.`}</li>
      <li parentName="ul">{`Surveys: Added parameters for new surveys.`}</li>
      <li parentName="ul">{`Dashboards: "BySide Support" Resources updated to "Coremedia".`}</li>
      <li parentName="ul">{`Bug fix: Fixed bug when filtering packages.`}</li>
      <li parentName="ul">{`Bug Fix: Error exporting panels on V4 by supervisors.`}</li>
      <li parentName="ul">{`Bug fix: Cobrowse delay.`}</li>
      <li parentName="ul">{`Bug fix: Internal Team Chat.`}</li>
      <li parentName="ul">{`Bug fix: Error getting status when an operator has HTML tags in the name.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      